import React, { useState, useEffect, useRef } from 'react';
import {
    Link,
    Routes,
    Route,
    useNavigate,
  } from 'react-router-dom';
import '../App.css';





const Leaderboard = () => {

    const [players, setPlayers] = useState([]);
    const navigate = useNavigate();



    useEffect(() => {
        //fetch('http://localhost:8081/players')
        fetch('https://teuyo.fr/api/players')
        .then(res => res.json())
        .then(data => {
            setPlayers(data);
        })
        .catch(err => console.log(err));
    }, []);

    return (
        <div className='App'>
            <div className='App-body'>
                <button onClick={() => navigate(-1)} className='profile'/>
                <h1>Classement</h1>
                <div className="leaderboard-container">
                    <h2>Top 10 Saison actuelle</h2>
                    <div className="leaderboard-list">
                        {players.slice(0, 10).map((player, index) => (
                            <div key={player.twitch_id} className="player">
                                <span>{index + 1}. {player.pseudo}</span>
                                <span>Niveau : {player.niveau}</span>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="leaderboard-container">
                <h2>Top 10 Saison 5</h2>
                    <div className="leaderboard-list">
                        <div className="player">
                            <span>1. Novalyss</span>
                            <span>Niveau : 293</span>
                        </div>
                        <div className="player">
                            <span>2. Shogfox_VT</span>
                            <span>Niveau : 262</span>
                        </div>
                        <div className="player">
                            <span>3. shieeruu</span>
                            <span>Niveau : 148</span>
                        </div>
                        <div className="player">
                            <span>4. Yelunna</span>
                            <span>Niveau : 95</span>
                        </div>
                        <div className="player">
                            <span>5. juju5777</span>
                            <span>Niveau : 77</span>
                        </div>
                        <div className="player">
                            <span>6. riskbibi</span>
                            <span>Niveau : 53</span>
                        </div>
                        <div className="player">
                            <span>7. Quozul</span>
                            <span>Niveau : 30</span>
                        </div>
                        <div className="player">
                            <span>8. ori_yume</span>
                            <span>Niveau : 27</span>
                        </div>
                        <div className="player">
                            <span>9. rogomeister</span>
                            <span>Niveau : 24</span>
                        </div>
                        <div className="player">
                            <span>10. RubyGanba</span>
                            <span>Niveau : 17</span>
                        </div>
                    </div>
                    </div>
                    <div className="leaderboard-container">
                    <h2>Top 10 Saison 4</h2>
                    <div className="leaderboard-list">
                        <div className="player">
                            <span>1. juju5777</span>
                            <span>Niveau : 208</span>
                        </div>
                        <div className="player">
                            <span>2. Shogfox_VT</span>
                            <span>Niveau : 151</span>
                        </div>
                        <div className="player">
                            <span>3. Vekotj</span>
                            <span>Niveau : 43</span>
                        </div>
                        <div className="player">
                            <span>4. rogomeister</span>
                            <span>Niveau : 39</span>
                        </div>
                        <div className="player">
                            <span>5. Novalyss</span>
                            <span>Niveau : 34</span>
                        </div>
                        <div className="player">
                            <span>6. Quozul</span>
                            <span>Niveau : 28</span>
                        </div>
                        <div className="player">
                            <span>7. Val0utre</span>
                            <span>Niveau : 25</span>
                        </div>
                        <div className="player">
                            <span>8. tonton_duck</span>
                            <span>Niveau : 25</span>
                        </div>
                        <div className="player">
                            <span>9. Xiarrow</span>
                            <span>Niveau : 24</span>
                        </div>
                        <div className="player">
                            <span>10. t_gwpl</span>
                            <span>Niveau : 19</span>
                        </div>
                    </div>
                    </div>
                    <div className="leaderboard-container">
                    <h2>Top 10 Saison 3</h2>
                    <div className="leaderboard-list">
                        <div className="player">
                            <span>1. juju5777</span>
                            <span>Niveau : 204</span>
                        </div>
                        <div className="player">
                            <span>2. Novalyss</span>
                            <span>Niveau : 105</span>
                        </div>
                        <div className="player">
                            <span>3. rogomeister</span>
                            <span>Niveau : 72</span>
                        </div>
                        <div className="player">
                            <span>4. Vekotj</span>
                            <span>Niveau : 61</span>
                        </div>
                        <div className="player">
                            <span>5. r1_dpn</span>
                            <span>Niveau : 49</span>
                        </div>
                        <div className="player">
                            <span>6. Quozul</span>
                            <span>Niveau : 24</span>
                        </div>
                        <div className="player">
                            <span>7. Shogfox_VT</span>
                            <span>Niveau : 21</span>
                        </div>
                        <div className="player">
                            <span>8. Gulliana_VT</span>
                            <span>Niveau : 14</span>
                        </div>
                        <div className="player">
                            <span>9. justgritt</span>
                            <span>Niveau : 11</span>
                        </div>
                        <div className="player">
                            <span>10. Wolfgaming0996</span>
                            <span>Niveau : 11</span>
                        </div>
                    </div>
                </div>
                <div className="leaderboard-container">
                    <h2>Top 10 Saison 2</h2>
                    <div className="leaderboard-list">
                        <div className="player">
                            <span>1. juju5777</span>
                            <span>Niveau : 501</span>
                        </div>
                        <div className="player">
                            <span>2. Novalyss</span>
                            <span>Niveau : 442</span>
                        </div>
                        <div className="player">
                            <span>3. athorielle</span>
                            <span>Niveau : 123</span>
                        </div>
                        <div className="player">
                            <span>4. Gulliana_VT</span>
                            <span>Niveau : 118</span>
                        </div>
                        <div className="player">
                            <span>5. Vekotj</span>
                            <span>Niveau : 91</span>
                        </div>
                        <div className="player">
                            <span>6. Mr_Coco_82</span>
                            <span>Niveau : 90</span>
                        </div>
                        <div className="player">
                            <span>7. rogomeister</span>
                            <span>Niveau : 81</span>
                        </div>
                        <div className="player">
                            <span>8. riskbibi</span>
                            <span>Niveau : 58</span>
                        </div>
                        <div className="player">
                            <span>9. tayashies</span>
                            <span>Niveau : 46</span>
                        </div>
                        <div className="player">
                            <span>10. cameleon_64</span>
                            <span>Niveau : 35</span>
                        </div>
                    </div>
                </div>
                <div className="leaderboard-container">
                    <h2>Top 10 Saison 1</h2>
                    <div className="leaderboard-list">
                        <div className="player">
                            <span>1. athorielle</span>
                            <span>Niveau : 500</span>
                        </div>
                        <div className="player">
                            <span>2. Novalyss</span>
                            <span>Niveau : 447</span>
                        </div>
                        <div className="player">
                            <span>3. riskbibi</span>
                            <span>Niveau : 305</span>
                        </div>
                        <div className="player">
                            <span>4. rogomeister</span>
                            <span>Niveau : 233</span>
                        </div>
                        <div className="player">
                            <span>5. 3ceraptor</span>
                            <span>Niveau : 122</span>
                        </div>
                        <div className="player">
                            <span>6. cameleon_64</span>
                            <span>Niveau : 89</span>
                        </div>
                        <div className="player">
                            <span>7. Quozul</span>
                            <span>Niveau : 87</span>
                        </div>
                        <div className="player">
                            <span>8. astrostormytb</span>
                            <span>Niveau : 74</span>
                        </div>
                        <div className="player">
                            <span>9. bnn_bn</span>
                            <span>Niveau : 70</span>
                        </div>
                        <div className="player">
                            <span>10. Tayashies</span>
                            <span>Niveau : 65</span>
                        </div>
                    </div>
                </div>
            </div>
            </div>
    );
};

export default Leaderboard;