import React, { useEffect, useState } from 'react';
import io from 'socket.io-client';

import waitingFight from '../images/gifs/waiting-fight.gif'
import monsterApparition from '../images/gifs/monster-apparition.gif'
import waitingFighters from '../images/gifs/waiting-fighters.gif'
import monsterAttack from '../images/gifs/monster-attack.gif'
import monsterCrit from '../images/gifs/monster-crit.gif'
import monsterDodge from '../images/gifs/monster-dodge.gif'
import playerAttack from '../images/gifs/player-attack.gif'
import playerCrit from '../images/gifs/player-crit.gif'
import playerDodge from '../images/gifs/player-dodge.gif'

function Bot() {
    const BOT_PWD = process.env.REACT_APP_BOT_PWD

    const [messageData, setMessageData] = useState({ message: "" }); 
    const [playerData, setPlayerData] = useState([]);


    

    useEffect(() => {
        //const socket = io('http://localhost:8082'); 
        const socket = io('wss://teuyo.fr', {
            path: "/api/socket.io",
            query: {
                token: BOT_PWD
            }
        });
        socket.on('messageFromBot', (data) => {
            console.log(data);
            setMessageData(data); 
        });

        socket.on('playerData', (data) => {
            console.log(data);
            setPlayerData(data); 
        });
    
        return () => {
            socket.disconnect(); 
        };
    }, []);


    const getGifPath = (message) => {
        switch (message) {
            case "waiting-fight":
                return waitingFight;
            case "monster-apparition":
                return monsterApparition;
            case "waiting-fighters":
                return waitingFighters;
            case "monster-attack":
                return monsterAttack;
            case "monster-crit":
                return monsterCrit;
            case "monster-dodge":
                return monsterDodge;
            case "player-attack":
                return playerAttack;
            case "player-crit":
                return playerCrit;
            case "player-dodge":
                return playerDodge;
        }
    };

    const renderPlayerBars = () => {
        if (playerData.length === 2 && playerData.every(player => player.santé > 0)) {
            return playerData.map((player, index) => (
                <div>
                <div className="player1-bar">
                    <h3 className='gif-player-name'>{playerData[0].nom}</h3>
                    <div className="health-bar" style={{ width: `${(playerData[0].santé / playerData[0].santéMax) * 100}px` }}></div>
                </div>
                <div className="player2-bar">
                    <h3 className='gif-player-name'>{playerData[1].nom}</h3>
                    <div className="health-bar" style={{ width: `${(playerData[1].santé / playerData[1].santéMax) * 100}px` }}></div>
                </div>
            </div>
            ));
        } else {
            return null; // Ne rien afficher si le combat est terminé
        }
    };

    return (
        <div>
            {messageData && <img src={getGifPath(messageData)} alt="Monster" />} 
            {renderPlayerBars()}
        </div>
    );
}

export default Bot;
