import { useState, useEffect } from 'react';
import axios from 'axios';
import '../App.css';
import { Tooltip } from 'react-tooltip'
import { Link } from 'react-router-dom';


import capuche from '../images/capuche.png'

import casquep1 from '../images/armor/casque-p1.png'
import plastronp1 from '../images/armor/plastron-p1.png'
import jambieresp1 from '../images/armor/jambieres-p1.png'
import bottesp1 from '../images/armor/bottes-p1.png'
import eppep1 from '../images/armor/epee-p1.png'
import ceinturep1 from '../images/armor/ceinture-p1.png'
import capep1 from '../images/armor/cape-p1.png'
import collierp1 from '../images/armor/collier-p1.png'
import anneaup1 from '../images/armor/anneau-p1.png'



const Inventory = () => {
    const CLIENT_ID = process.env.REACT_APP_CLIENT_ID
    const REDIRECT_URI = process.env.REACT_APP_REDIRECT_URI

    const [accessToken, setAccessToken] = useState(null);
    const [twitchId, setTwitchId] = useState(null);
    const [inventory, setInventory] = useState([])
    const [equiped, setEquiped] = useState([])
    const [player, setPlayer] = useState(null)
    const [selectedItem, setSelectedItem] = useState(null);
    const [players, setPlayers] = useState([]);
    const [playerRanking, setPlayerRanking] = useState(null);


    const emptySlotImages = {
        'Casque': casquep1,
        'Plastron': plastronp1,
        'Jambieres': jambieresp1,
        'Bottes': bottesp1,
        'Cape': capep1,
        'Ceinture': ceinturep1,
        'Anneau': anneaup1,
        'Collier': collierp1,
        'Epee': eppep1,
    };




    useEffect(() => {
        const getParamsFromURL = () => {
            const params = {};
            window.location.hash.substr(1).split('&').forEach(param => {
                const [key, value] = param.split('=');
                params[key] = value;
            });
            return params;
        };
    
        const params = getParamsFromURL();
        if (params.access_token) {
            setAccessToken(params.access_token);
        }
    }, []);

    useEffect(() => {
        if (accessToken) {
            getUserTwitchId(accessToken);
        }
    }, [accessToken]);
    
    useEffect(() => {
        if (twitchId) {
            getInventory(twitchId);
            getEquiped(twitchId);
            getPlayer(twitchId);
        }
    }, [twitchId]);

    useEffect(() => {
        //fetch('http://localhost:8081/players')
        fetch('https://teuyo.fr/api/players')
            .then(res => {
                if (!res.ok) {
                    throw new Error('Failed to fetch');
                }
                return res.json();
            })
            .then(data => {
                if (!Array.isArray(data)) {
                    throw new Error('Invalid data format');
                }
                const playerIndex = data.findIndex(p => p.twitch_id === player.twitch_id);
                const playerRanking = playerIndex !== -1 ? playerIndex + 1 : null;
                setPlayers(data);
                setPlayerRanking(playerRanking);
            })
            .catch(err => console.error(err));
    }, [player]);
    
    
    

    const params = [CLIENT_ID, REDIRECT_URI,'token','chat:read','']

    function getPlayer(twitchId) {
        //fetch(`http://localhost:8081/player/${twitchId}`)
        fetch(`https://teuyo.fr/api/player/${twitchId}`)
        .then(res => res.json())
        .then(data => setPlayer(data[0]))
        .catch(err => console.log(err));
    }

    function getInventory(twitchId) {
        //fetch(`http://localhost:8081/inventory/${twitchId}`)
        fetch(`https://teuyo.fr/api/inventory/${twitchId}`)
        .then(res => res.json())
        .then(data => setInventory(data))
        .catch(err => console.log(err));
    }

    function getEquiped(twitchId) {
        //fetch(`http://localhost:8081/equiped/${twitchId}`)
        fetch(`https://teuyo.fr/api/equiped/${twitchId}`)
        .then(res => res.json())
        .then(data => setEquiped(data))
        .catch(err => console.log(err));
    }

    function deleteItem(itemId) {
        //fetch(`http://localhost:8081/item/${itemId}`, {
        fetch(`https://teuyo.fr/api/item/${itemId}`, {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
        .then(res => {
            if (res.ok) {
                getInventory(twitchId);
            } else {
                console.error('Failed to delete item');
            }
        })
        .catch(err => console.error(err));
    }

    function equipItem(itemId) {
        //fetch(`http://localhost:8081/item/equip/${itemId}`, {
        fetch(`https://teuyo.fr/api/item/equip/${itemId}`, {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
        .then(res => {
            if (res.ok) {
                getInventory(twitchId);
                getEquiped(twitchId);
                getPlayer(twitchId)
            } else {
                console.error('Failed to equip item');
            }
        })
        .catch(err => console.error(err));
    }

    function getUserTwitchId(user_access_token) {
        axios.get('https://api.twitch.tv/helix/users', {
            headers: {
                "Authorization": `Bearer ${user_access_token}`,
                "Client-Id": CLIENT_ID
            }
        })
        .then(response => {
            setTwitchId(response.data.data[0].id)
        })
        .catch(error => {
            console.error(error);
        });
    }

    function choseImage(item) {
        if (item.type == 'Casque'){
            return casquep1
        }
        else if (item.type == 'Plastron'){
            return plastronp1
        }
        else if (item.type == 'Jambieres'){
            return jambieresp1
        }
        else if (item.type == 'Bottes'){
            return bottesp1
        }
        else if (item.type == 'Epee'){
            return eppep1
        }
        else if (item.type == 'Ceinture'){
            return ceinturep1
        }
        else if (item.type == 'Cape'){
            return capep1
        }
        else if (item.type == 'Collier'){
            return collierp1
        }
        else if (item.type == 'Anneau'){
            return anneaup1
        }
    }

    function compareItemsByTypeOrder(a, b) {
        const itemOrder = ['Casque', 'Plastron', 'Jambieres', 'Bottes', 'Cape', 'Ceinture', 'Anneau', 'Collier', 'Epee'];
        const typeIndexA = itemOrder.indexOf(a.type);
        const typeIndexB = itemOrder.indexOf(b.type);
        return typeIndexA - typeIndexB;
    }

    function compareItemsByLevel(a, b) {
        return b.lvl - a.lvl;
    }

    function handleItemClick(item) {
        setSelectedItem(item);
    }

    function getEquippedStats(itemType) {
        const equippedItem = equiped.find(item => item.type === itemType);
        if (equippedItem) {
            return {
                lvl: equippedItem.lvl,
                force_stat: equippedItem.force_stat,
                degats_arme_min: equippedItem.degats_arme_min,
                degats_arme_max: equippedItem.degats_arme_max,
                dexterite: equippedItem.dexterite,
                endurance: equippedItem.endurance,
                chance: equippedItem.chance,
                armure: equippedItem.armure
            };
        }
        return null; // Retournez null si aucun objet équipé correspondant n'est trouvé
    }
    

    function compareStats(statItem, statEquipped) {
        if (statItem > statEquipped) {
            return 'strongerStat';
        } else if (statItem < statEquipped) {
            return 'weakerStat';
        } else {
            return '';
        }
    }


    return (
      <div className="App">
        <div className="App-body">
        <Link to="/leaderboard">
            <button className='leaderboard'/>
        </Link>
            {!accessToken && (
            <a href={`https://id.twitch.tv/oauth2/authorize?client_id=${params[0]}&redirect_uri=https://teuyo.fr/&response_type=${params[2]}&scope=${params[3]}&state=${params[4]}`} target="_self" class="twitch-button">
                Connectez vous avec twitch
            </a>
            //<a href={`https://id.twitch.tv/oauth2/authorize?client_id=${params[0]}&redirect_uri=http://localhost:3000&response_type=${params[2]}&scope=${params[3]}&state=${params[4]}`} target="_self" class="twitch-button">
                //Connectez vous avec twitch
            //</a>
            )}
            {accessToken && (
            <div>
                {player && (
                    <div>
                        <p style={{paddingBottom: "20px"}}>{player.pseudo} #{playerRanking}</p>
                        <div className='equiped'>
                            <img className='caracter' src={capuche}></img>
                            <span className='lvl' >Niveau <br></br><br></br>{player.niveau}</span>
                            <span className='force' data-tooltip-id="force">Force<br></br><br></br>{player.force_stat}</span>
                            <Tooltip id="force" style={{ backgroundColor: "#212121", color: "#b4b4b4" }}>
                            <span>Dégâts : Dégâts d'arme * (1 + Force) ≈ {Math.round((player.degats_arme_min + player.degats_arme_max)/2*player.degats)}</span>
                            </Tooltip>
                            <span className='dexterite' data-tooltip-id="dexterite">Dextérité<br></br><br></br>{player.dexterite}</span>
                            <Tooltip id="dexterite" style={{ backgroundColor: "#212121", color: "#b4b4b4" }}>
                            <span>Esquive : Dextérité * 9 / niveau * 25 = {Math.round(player.dexterite * 9 / player.niveau * 25)}%<br></br>Max 50%</span>
                            </Tooltip>
                            <span className='endurance' data-tooltip-id="endurance">Endurance<br></br><br></br>{player.endurance}</span>
                            <Tooltip id="endurance" style={{ backgroundColor: "#212121", color: "#b4b4b4" }}>
                            <span>Vie : 1 + Endurance * 6 * 10 * (niveau/9) = {Math.round(player.endurance * 6 * 10 * (player.niveau/9))}</span>
                            </Tooltip>
                            <span className='chance' data-tooltip-id="chance">Chance<br></br><br></br>{player.chance}</span>
                            <Tooltip id="chance" style={{ backgroundColor: "#212121", color: "#b4b4b4" }}>
                            <span>Taux de critique : chance * 9 / niveau * 25 = {Math.round(player.chance * 9 / player.niveau * 25)}%</span>
                            </Tooltip>
                            <span className='armure' data-tooltip-id="armure">Armure<br></br><br></br>{player.armure}</span>
                            <Tooltip id="armure" style={{ backgroundColor: "#212121", color: "#b4b4b4" }}>
                            <span>Réduction de dégâts : armure * 9 / niveau * 25 = {Math.round(player.armure * 9 / player.niveau * 25)}%<br></br>Max 50%</span>
                            </Tooltip>

                            {['Casque', 'Plastron', 'Jambieres', 'Bottes', 'Cape', 'Ceinture', 'Anneau', 'Collier', 'Epee'].map((type, index) => {
                            const equippedItem = equiped.find(item => item.type === type);
                            return (
                                <div className={`equiped${index}`} key={index}>
                                    {equippedItem ? (
                                        <div>
                                            <img className='img'  data-tooltip-place="bottom" src={choseImage(equippedItem)} data-tooltip-id={`inventaire${equippedItem.item_id}`}/>
                                            <Tooltip id={`inventaire${equippedItem.item_id}`} style={{ backgroundColor: "#212121", color: "#b4b4b4", zIndex: 9999}}>
                                                <span>{equippedItem.type.charAt(0).toUpperCase() + equippedItem.type.slice(1)}</span>
                                                <br></br>
                                                <br></br>
                                                <div className='justify'>
                                                    <span>Niveau {equippedItem.lvl}<br></br><br></br></span>
                                                    {equippedItem.force_stat !== 0 && (
                                                        <span>Force {equippedItem.force_stat}<br></br></span>
                                                    )}
                                                    {equippedItem.degats_arme_min !== 0 && (
                                                        <span>Dégâts {equippedItem.degats_arme_min} / {equippedItem.degats_arme_max}<br></br></span>
                                                    )}
                                                    {equippedItem.dexterite !== 0 && (
                                                        <span>Dextérité {equippedItem.dexterite}<br></br></span>
                                                    )}
                                                    {equippedItem.endurance !== 0 && (
                                                        <span>Endurance {equippedItem.endurance}<br></br></span>
                                                    )}
                                                    {equippedItem.chance !== 0 && (
                                                        <span>Chance {equippedItem.chance}<br></br></span>
                                                    )}
                                                    {equippedItem.armure !== 0 && (
                                                        <span>Armure {equippedItem.armure}<br></br></span>
                                                    )}
                                                </div>
                                            </Tooltip>
                                        </div>
                                    ) : (
                                        <div>
                                            <img className='transparent-image' src={emptySlotImages[type]} />
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                        </div>
                    </div>
                )}
                <p style={{paddingBottom: "20px"}}>Inventaire</p>
                <div className='inventory'>
                {inventory.sort(compareItemsByLevel).map(item => {
                const equippedStats = getEquippedStats(item.type);

                        return (
                            <div>
                            <div 
                                key={item.item_id}
                                onClick={() => handleItemClick(item)} 
                                className={selectedItem === item ? 'selected' : ''}
                                data-tooltip-id={`inventaire${item.item_id}`}
                            >
                                <div className={`item ${selectedItem === item ? 'selected' : ''}`}>
                                    <img className='img' src={choseImage(item)}/>
                                </div>
                                <Tooltip
                                id={`inventaire${item.item_id}`}
                                style={{ 
                                    backgroundColor: "#212121", 
                                    color: "#b4b4b4", 
                                    zIndex: 3
                                }}
                                >                                    
                                <span>{item.type.charAt(0).toUpperCase() + item.type.slice(1)}</span>
                                    <br></br>
                                    <br></br>
                                    {equippedStats && (
                                        <div className='justify'>
                                        <span className='justify-flex'><span>Niveau &nbsp;</span> <span>{item.lvl} <span className={compareStats(item.lvl, equippedStats.lvl)}>
                                            ({item.lvl - equippedStats.lvl > 0 ? `+${item.lvl - equippedStats.lvl}` : item.lvl - equippedStats.lvl})
                                        </span><br></br><br></br></span></span>                                            {(item.force_stat !== 0 || equippedStats.force_stat !== 0) && (
                                                <span className='justify-flex'><span>Force &nbsp;</span> <span>{item.force_stat} <span className={compareStats(item.force_stat, equippedStats.force_stat)}>
                                                ({item.force_stat - equippedStats.force_stat > 0 ? `+${item.force_stat - equippedStats.force_stat}` : item.force_stat - equippedStats.force_stat})
                                                </span><br></br></span></span>                                            )}
                                                {(item.degats_arme_min !== 0 || equippedStats.degats_arme_min !== 0) && (
                                                <span className='justify-flex'><span>Dégâts &nbsp;</span> <span>{item.degats_arme_min} / {item.degats_arme_max} <span className={compareStats(item.degats_arme_min, equippedStats.degats_arme_min)}>
                                                ({item.degats_arme_min - equippedStats.degats_arme_min > 0 ? `+${item.degats_arme_min - equippedStats.degats_arme_min}` : item.degats_arme_min - equippedStats.degats_arme_min}</span> / <span className={compareStats(item.degats_arme_max, equippedStats.degats_arme_max)}>
                                                {item.degats_arme_max - equippedStats.degats_arme_max > 0 ? `+${item.degats_arme_max - equippedStats.degats_arme_max}` : item.degats_arme_max - equippedStats.degats_arme_max})
                                                </span><br></br></span></span>
                                            )}
                                            {(item.dexterite !== 0 || equippedStats.dexterite !== 0) && (
                                                <span className='justify-flex'><span>Dextérité &nbsp;</span> <span>{item.dexterite} <span className={compareStats(item.dexterite, equippedStats.dexterite)}>
                                                ({item.dexterite - equippedStats.dexterite > 0 ? `+${item.dexterite - equippedStats.dexterite}` : item.dexterite - equippedStats.dexterite})
                                                </span><br></br></span></span>
                                            )}
                                            {(item.endurance !== 0 || equippedStats.endurance !== 0) && (
                                                <span className='justify-flex'><span>Endurance &nbsp;</span> <span>{item.endurance} <span className={compareStats(item.endurance, equippedStats.endurance)}>
                                                ({item.endurance - equippedStats.endurance > 0 ? `+${item.endurance - equippedStats.endurance}` : item.endurance - equippedStats.endurance})
                                                </span><br></br></span></span>
                                            )}
                                            {(item.chance !== 0 || equippedStats.chance !== 0) && (
                                                <span className='justify-flex'><span>Chance &nbsp;</span> <span>{item.chance} <span className={compareStats(item.chance, equippedStats.chance)}>
                                                ({item.chance - equippedStats.chance > 0 ? `+${item.chance - equippedStats.chance}` : item.chance - equippedStats.chance})
                                                </span><br></br></span></span>
                                            )}
                                            {(item.armure !== 0 || equippedStats.armure !== 0) && (
                                                <span className='justify-flex'><span>Armure &nbsp;</span> <span>{item.armure} <span className={compareStats(item.armure, equippedStats.armure)}>
                                                ({item.armure - equippedStats.armure > 0 ? `+${item.armure - equippedStats.armure}` : item.armure - equippedStats.armure})
                                                </span><br></br></span></span>
                                            )}
                                        </div>
                                    )}
                                </Tooltip>
                                {selectedItem === item && (
                                    <div className="item-overlay">
                                        <button onClick={() => equipItem(item.item_id)} className='add'/>
                                        <button onClick={() => deleteItem(item.item_id)} className='delete'/>
                                    </div>
                                )}
                            </div>
                            </div>
                        );
                    })}
                </div>
            </div>
            )}
        </div>
      </div>
    );
  };
  
  export default Inventory