import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Inventory from "./pages/Inventory";
import Leaderboard from './pages/Leaderboard';
import Bot from './pages/Bot'

const BOT_URL = process.env.REACT_APP_BOT_URL

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Inventory />}></Route>
        <Route path="/leaderboard" element={<Leaderboard />} />
        <Route path={`/${BOT_URL}`} element={<Bot />} />
      </Routes>
    </BrowserRouter>
  );
}